import { FabButton, IStaffTag, Label, List, ListItem, StringUtils, Switch, useConfirmContext } from '@localina/core';
import { AddIcon } from '@localina/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateRestaurantMutation } from '../../api/queries/restaurants';
import { useStaffTags } from '../../api/queries/staffTags';
import { Page } from '../../components';
import StaffTagView from './StaffTagView';

type TDrawerState = {
    open: boolean;
    selectedStaffTag?: IStaffTag;
};

const defaultDrawerState = {
    open: false,
    selectedStaffTag: undefined,
};
const StaffTags: React.FC = () => {
    const { t } = useTranslation();

    const staffTagsQuery = useStaffTags();
    const updateRestaurantMutation = useUpdateRestaurantMutation();

    const [drawerState, setDrawerState] = useState<TDrawerState>(defaultDrawerState);

    const { alert } = useConfirmContext();

    const handleItemClick = (item: IStaffTag) => () => {
        setDrawerState({
            open: true,
            selectedStaffTag: item,
        });
    };

    const handleCreateStaffTag = () => {
        setDrawerState({ open: true, selectedStaffTag: undefined });
    };

    const onCloseDialog = () => {
        setDrawerState(defaultDrawerState);
    };

    const toggleStaffTagMandatorySwitch = (value: boolean) => {
        if (value && staffTagsQuery.isSuccess && !staffTagsQuery.data.staffTags.length) {
            alert({ msg: t('staffTags.configuration.unableToSetStaffTagMandatory') });
        } else {
            updateRestaurantMutation.mutate({ staffTagMandatory: value });
        }
    };

    const toggleStaffTagEnabledSwitch = (value: boolean) => {
        if (value && staffTagsQuery.isSuccess && !staffTagsQuery.data.staffTags.length) {
            alert({ msg: t('staffTags.configuration.unableToSetStaffTagEnabled') });
        } else {
            updateRestaurantMutation.mutate({ staffTagEnabled: value });
        }
    };

    useEffect(() => {
        if (
            staffTagsQuery.isSuccess &&
            !staffTagsQuery.data.staffTags.length &&
            staffTagsQuery.data.staffTagMandatory
        ) {
            updateRestaurantMutation.mutate({ staffTagMandatory: false, staffTagEnabled: false });
        }
    }, [staffTagsQuery.data?.staffTags.length, staffTagsQuery.data?.staffTagMandatory]);

    return (
        <Page
            name="staff-tags"
            title={t('staffTags.pageTitle')}
            isLoading={staffTagsQuery.isLoading || updateRestaurantMutation.isLoading}
        >
            <div className="staff-tags-configuration-wrapper">
                <Switch
                    label={t('staffTags.configuration.staffTagIsEnabled')}
                    checked={staffTagsQuery.isSuccess && staffTagsQuery.data.staffTagEnabled}
                    onChange={toggleStaffTagEnabledSwitch}
                />
                <Switch
                    label={t('staffTags.configuration.staffTagIsMandatory')}
                    disabled={!staffTagsQuery.data?.staffTagEnabled}
                    checked={staffTagsQuery.isSuccess && staffTagsQuery.data.staffTagMandatory}
                    onChange={toggleStaffTagMandatorySwitch}
                />
            </div>
            <List>
                {!staffTagsQuery.isLoading && staffTagsQuery.isSuccess && (
                    <>
                        {staffTagsQuery.data.staffTags.length === 0 && (
                            <div className={'staff-tags-empty-label-wrapper'}>
                                <Label type={'text'} value={t('staffTags.noStaffTagsCreated')} />
                            </div>
                        )}
                        {staffTagsQuery.data.staffTags.map((item) => (
                            <ListItem
                                key={item.id}
                                title={<StaffTagListItemTitle item={item} />}
                                onClick={handleItemClick(item)}
                            />
                        ))}
                    </>
                )}
            </List>
            <FabButton icon={<AddIcon />} onClick={handleCreateStaffTag} className="staff-tags-fab-button" fixed />
            <StaffTagView open={drawerState.open} onClose={onCloseDialog} selectedTag={drawerState.selectedStaffTag} />
        </Page>
    );
};

interface IStaffTagListItemTitleProps {
    item: IStaffTag;
}

const StaffTagListItemTitle = ({ item }: IStaffTagListItemTitleProps) => (
    <div className={'staff-tag-title'}>
        <Label type={'text'} value={StringUtils.combineStrings([item.firstName, item.lastName], ', ')} />
        <Label type={'text'} value={item.staffTag} />
    </div>
);

export default StaffTags;
