import { Drawer, IReservation, Label, ReservationStatus, TLanguageKey, useConfirmContext } from '@localina/core';
import { CloseIcon } from '@localina/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReservation } from '../../api/queries/reservations';
import { useAreas, useVirtualAreas } from '../../api/queries/restaurantAreas';
import { usePreservedRestaurant } from '../../api/queries/restaurants';
import RequestedReservationActionButtons from '../ReservationStatus/RequestedReservationActionButtons';
import RequestedReservationDetails from './RequestedReservationDetails';

interface IRequestedReservationViewProps {
    open: boolean;
    onClose: () => void;
    reservation?: IReservation;
}

const RequestedReservationView = (props: IRequestedReservationViewProps) => {
    const { t, i18n } = useTranslation();
    const { confirm } = useConfirmContext();
    const [reservationProcessed, setReservationProcessed] = useState<boolean>(false);

    const reservationQuery = useReservation(props.reservation?.id || '', {
        enabled: Boolean(props.reservation?.id),
        initialData: props.reservation,
    });

    const reservation = reservationQuery.data;

    const isProcessed = reservation && reservation.status !== ReservationStatus.APPROVAL_PENDING;

    const areasQuery = useAreas();
    const virtualAreasQuery = useVirtualAreas();

    const restaurantQuery = usePreservedRestaurant(false);

    const shift = useMemo(
        () => restaurantQuery.data?.configuration.shifts.find((s) => s.id === reservation?.shiftId),
        [restaurantQuery.data, reservation?.shiftId],
    );
    const shiftName = shift?.name[i18n.language as TLanguageKey];

    const area = useMemo(() => {
        const targetArea = areasQuery.data?.areas.find((a) => reservation?.areaIds.includes(a.id));
        return targetArea ?? virtualAreasQuery.data?.virtualAreas.find((a) => reservation?.areaIds.includes(a.id));
    }, [areasQuery.data, virtualAreasQuery.data, reservation?.areaIds]);

    const onSuccess = () => {
        setReservationProcessed(true);
        props.onClose();
    };

    const confirmCloseDrawer = async () => {
        if (
            reservationProcessed ||
            isProcessed ||
            (await confirm({
                title: t('reservations.requestedReservationView.confirmClose.title'),
            })) === 'yes'
        ) {
            props.onClose();
        }
    };

    useEffect(() => {
        if (props.open) {
            setReservationProcessed(false);
        }
    }, [props.open]);

    return (
        <Drawer open={props.open} onClose={confirmCloseDrawer} position="right" className={'reservation-view'}>
            <div className="localina-drawer__header">
                <CloseIcon onClick={confirmCloseDrawer} />
                <Label type="title" value={t('reservations.requestedReservationView.title')} variant="h5" />
            </div>
            {!!reservation && (
                <div className="localina-drawer__body__content">
                    <RequestedReservationDetails
                        reservationDateTime={reservation.reservationDateTime}
                        guestInfo={reservation.guestInfo}
                        comment={reservation.comment}
                        area={area}
                        shiftName={shiftName}
                        status={reservation.status}
                    />
                </div>
            )}
            {reservation && !isProcessed && (
                <div className="localina-drawer__footer">
                    <RequestedReservationActionButtons
                        reservation={reservation}
                        onSuccess={onSuccess}
                        isProcessed={reservation.status !== ReservationStatus.APPROVAL_PENDING}
                    />
                </div>
            )}
        </Drawer>
    );
};

export default RequestedReservationView;
